<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-if="inAtivaVinculacao">
      <v-col cols="12" :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-card>
          <v-card-title class="justify-center">
            <div class="text-center">
              <h2>Vinculação</h2>
            </div>
          </v-card-title>
          <v-card-text class="d-flex flex-column mb-6">
            <v-btn @click="mostrarDataGridGerente(true)">
              GERÊNCIA REGIONAL
            </v-btn>
            <v-btn class="mt-6" @click="mostrarDataGridDiretor(true)">
              DIRETOR DE ESCOLA
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- INÍCIO GRID GERENTE -->
    <div v-if="inAtivaDataGridGerente">
      <v-col
        cols="12"
        :class="{ shorten: $vuetify.breakpoint.lgAndUp }"
        class="text-right"
      >
        <v-btn @click="mostrarDataGridGerente(false)"
          ><v-icon> mdi-arrow-left-thick </v-icon></v-btn
        >
      </v-col>
      <v-row>
        <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
          <v-data-table
            :headers="grid.headersGerente"
            :items="filteredItems"
            :loading="grid.loading"
            loading-text="Carregando... aguarde"
            locale="pt"
            class="elevation-1"
          >
            <template v-slot:item.nome="{ item }">
              <span>{{ item.nome }}</span>
            </template>

            <template v-slot:item.cpf="{ item }">
              <span>{{ item.cpf }}</span>
            </template>

            <template v-slot:item.gre="{ item }">
              <span>{{ item.gre }}</span>
            </template>

            <template v-slot:item.actionsGerente="{ item }">
              <div>
                <v-icon
                  v-if="$can('edit', 'usuario')"
                  color="black"
                  class="mr-1"
                  @click="editItemGerente(item)"
                >
                  mdi-cog
                </v-icon>
              </div>
            </template>
          </v-data-table>

          <v-navigation-drawer
            v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
            v-model="mobileFilter"
            :permanent="$vuetify.breakpoint.lgAndUp"
            :absolute="$vuetify.breakpoint.lgAndUp"
            :fixed="$vuetify.breakpoint.mdAndDown"
            right
            class="elevation-1"
          >
            <v-card elevation="0" class="pa-4">
              <v-card-title class="grey--text text--darken-2"
                >Filtros</v-card-title
              >
              <v-text-field
                v-model="grid.filters.nome"
                label="Nome"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="grid.filters.cpf"
                label="CPF"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="grid.filters.gre"
                label="GRE"
                dense
                solo
              ></v-text-field>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
              </v-card-actions>
            </v-card>
          </v-navigation-drawer>
        </v-col>
      </v-row>
    </div>
    <!-- FIM GRID GERENTE -->

    <!--Dialog Pergunta Gerente -->
    <v-row justify="center">
      <v-dialog v-model="dialogPerguntaGerente" persistent max-width="500px">
        <v-card>
          <v-card-title style="background-color: red">
            <span class="text-h5 text-white white--text">Atenção!</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h3>Você tem certeza que deseja retirar o vínculo?</h3>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="fecharDialogoGerente"> NÃO </v-btn>
            <v-btn @click="desvincularUsuarioGre"> SIM </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fim Dialog Pergunta Gerente -->
    <!--Dialog Pergunta Gerente -->
    <v-row justify="center">
      <v-dialog v-model="dialogPerguntaDiretor" persistent max-width="500px">
        <v-card>
          <v-card-title style="background-color: red">
            <span class="text-h5 text-white white--text">Atenção!</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h3>Você tem certeza que deseja retirar o vínculo?</h3>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="fecharDialogoDiretor"> NÃO </v-btn>
            <v-btn @click="desvincularUsuarioDiretor"> SIM </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fim Dialog Pergunta Gerente -->
    <!-- Dialog Gerente-->
    <v-row justify="center">
      <v-dialog v-model="dialogGerente" persistent max-width="430px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Vincular Gerente Regional com GRE</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="usuarioAssociado"
                      :items="gres"
                      item-text="nome"
                      item-value="codigo"
                      label="GRE"
                      dense
                      outlined
                      return-object
                      single-line
                      :rules="rules.gre"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bt" outline-depressed @click="fecharDialogoGerente">
              CANCELAR
            </v-btn>
            <v-btn
              class="bt"
              :disabled="usuarioAssociado == undefined"
              outline-depressed
              @click="associarUsuarioGre"
            >
              CONFIRMAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- INÍCIO GRID DIRETOR -->
    <div v-if="inAtivaDataGridDiretor">
      <v-col
        cols="12"
        :class="{ shorten: $vuetify.breakpoint.lgAndUp }"
        class="text-right"
      >
        <v-btn @click="mostrarDataGridDiretor(false)"
          ><v-icon> mdi-arrow-left-thick </v-icon></v-btn
        >
      </v-col>
      <v-row>
        <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
          <v-data-table
            :headers="gridDiretor.headersDiretor"
            :items="filteredItemsDiretor"
            :loading="gridDiretor.loading"
            loading-text="Carregando... aguarde"
            locale="pt"
            class="elevation-1"
          >
            <template v-slot:item.nomeDiretor="{ item }">
              <span>{{ item.nomeDiretor }}</span>
            </template>

            <template v-slot:item.cpfDiretor="{ item }">
              <span>{{ item.cpfDiretor }}</span>
            </template>

            <template v-slot:item.nomeDaEscola="{ item }">
              <span>{{ item.nomeDaEscola }}</span>
            </template>

            <template v-slot:item.actionsDiretor="{ item }">
              <div>
                <v-icon
                  v-if="$can('edit', 'usuario')"
                  color="black"
                  class="mr-1"
                  @click="editItemDiretor(item)"
                >
                  mdi-cog
                </v-icon>
              </div>
            </template>
          </v-data-table>

          <v-navigation-drawer
            v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
            v-model="mobileFilter"
            :permanent="$vuetify.breakpoint.lgAndUp"
            :absolute="$vuetify.breakpoint.lgAndUp"
            :fixed="$vuetify.breakpoint.mdAndDown"
            right
            class="elevation-1"
          >
            <v-card elevation="0" class="pa-4">
              <v-card-title class="grey--text text--darken-2"
                >Filtros</v-card-title
              >
              <v-text-field
                v-model="gridDiretor.filters.nomeDiretor"
                label="Nome"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="gridDiretor.filters.cpfDiretor"
                label="CPF"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="gridDiretor.filters.nomeDaEscola"
                label="Escola"
                dense
                solo
              ></v-text-field>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
              </v-card-actions>
            </v-card>
          </v-navigation-drawer>
        </v-col>
      </v-row>
    </div>
    <!-- FIM GRID DIRETOR -->
    <!-- Dialog Diretor-->
    <v-row justify="center">
      <v-dialog v-model="dialogDiretor" persistent max-width="430px">
        <v-card>
          <v-card-title>
            <span class="text1-h5">Vincular Diretor com Escola</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="greSelecionada"
                      :items="gres"
                      item-text="nome"
                      item-value="codigo"
                      :search-input.sync="filtroListaGre"
                      label="GRE"
                      dense
                      outlined
                      return-object
                      :rules="rules.gre"
                      @change="handleSearch"
                    ></v-select>
                    <v-autocomplete
                      v-model="escolaSelecionada"
                      :items="escolas"
                      item-text="nome"
                      item-value="codigoMec"
                      :search-input.sync="filtroListaEscola"
                      cache-items
                      flat
                      hide-no-data
                      hide-details
                      label="Escola"
                      dense
                      outlined
                      :disabled="escolas.length == 0"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bt" outline-depressed @click="fecharDialogoDiretor">
              CANCELAR
            </v-btn>
            <v-btn
              class="bt"
              :disabled="escolaSelecionada == undefined"
              outline-depressed
              @click="associarUsuarioDiretor"
            >
              CONFIRMAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pageLoadingMixin from "mixins/page-loading.mixin";
import notificationMixin from "mixins/notification.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";

const { mapGetters: postLoginGetters } =
  createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "ManagerUserVinculacao",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getListGerente();
    this.getListDiretor();
  },

  mounted() {
    if (this.gres == [] || this.gres == undefined || this.gres.length == 0) {
      this.getListGre();
    }
  },

  components: {},
  data: () => ({
    loadingComboBox: false,
    editedIndex: -1,
    editedIndexDiretor: -1,
    vincularUsuarioGre: true,
    inAtivaVinculacao: true,
    inAtivaDataGridDiretor: false,
    inAtivaDataGridGerente: false,
    indexUser: -1,
    indexUserDiretor: -1,
    dialogGerente: false,
    dialogDiretor: false,
    dialogPerguntaGerente: false,
    dialogPerguntaDiretor: false,
    isFormValid: undefined,
    isProcessing: false,
    isMobileFilterVisible: false,
    mobileFilter: null,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Vinculação", to: "/admin/vinculacao" },
    ],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nome: "",
        cpf: "",
        gre: "",
      },
      headersGerente: [
        { text: "Id", value: "id", align: " d-none" },
        { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "GRE", value: "gre" },
        {
          text: "Ações",
          value: "actionsGerente",
          align: "center",
          sortable: false,
        },
      ],
      data: [],
    },
    gridDiretor: {
      loading: false,
      filtered: false,
      filters: {
        nomeDiretor: "",
        cpfDiretor: "",
        nomeDaEscola: "",
      },
      headersDiretor: [
        { text: "Id", value: "id", align: " d-none" },
        { text: "Nome", value: "nomeDiretor" },
        { text: "CPF", value: "cpfDiretor" },
        { text: "Escola", value: "nomeDaEscola" },
        {
          text: "Ações",
          value: "actionsDiretor",
          align: "center",
          sortable: false,
        },
      ],
      data: [],
    },
    gres: [],
    escolas: [],
    escolhas: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    usuarioAssociado: undefined,
    usuarioDiretor: undefined,
    greSelecionada: undefined,
    escolaSelecionada: undefined,
    filtroListaGre: "",
    filtroListaEscola: "",
    cleanUser: {
      id: 0,
      nome: "",
      cpf: "",
      gre: "",
      nomeDeUsuario: "",
      codigo: "",
      greId: "",
      usuario: "",
    },
    defaultUser: {
      id: 0,
      nome: "",
      cpf: "",
      gre: "",
      nomeDeUsuario: "",
      codigo: "",
      greId: "",
      usuario: "",
    },
  }),
  methods: {
    getListGerente() {
      this.showPageLoading();
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.admin.vinculacao.getListaDeGerenteRegional, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          this.grid.data = data.usuarios.map((item) => {
            return {
              nome: item.nome,
              cpf: item.cpf,
              gre: this.verificarSeStringNula(item.gre),
              id: item.usuarioId,
              gestorId: item.gestorId,
            };
          });
        })
        .catch(({ response }) => {
          if (response && response.nome) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar a lista de gerentes."
            );
          }
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    getListDiretor() {
      this.showPageLoading();
      this.gridDiretor.loading = true;
      this.$api()
        .get(configurations.api.admin.vinculacao.getListaDeDiretor, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          this.gridDiretor.data = data.usuariosDiretor.map((item) => {
            return {
              nomeDiretor: item.nomeDiretor,
              cpfDiretor: item.cpfDiretor,
              id: item.usuarioId,
              gestorId: item.gestorId,
              codigoMec: item.codigoMec,
              nomeDaEscola: this.verificarSeStringNula(item.nomeDaEscola),
            };
          });
        })
        .catch(({ response }) => {
          if (response && response.nome) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos consultar a lista de Diretor.");
          }
        })
        .then(() => {
          this.gridDiretor.loading = false;
          this.hidePageLoading();
        });
    },
    verificarSeStringNula(valor) {
      if(valor)
        return valor;
      return "";
    },
    getListGre() {
      this.$api()
        .get(configurations.api.admin.vinculacao.getListaDeGres, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          this.gres = data.gres.map((item) => {
            return {
              nome: item.nome,
              codigo: item.codigo,
            };
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos consultar a lista de gres.");
          }
        });
    },
    getListaDeEscolas() {
      this.$api()
        .get(configurations.api.admin.vinculacao.getListaDeEscolas, {
          params: {
            nomeDeUsuario: this.user.username,
            greId: this.greSelecionada.codigo
          },
        })
        .then(({ data }) => {
          this.escolas = data.escolas;
        })
        .catch(({ response }) => {
          this.exibirMensagemDeRetorno(response, "Não conseguimos consultar a lista de escolas para essa GRE.");
        });
    },
    handleSearch() {
      if(this.greSelecionada && this.greSelecionada.codigo) {
        this.getListaDeEscolas();
      }
    },
    exibirMensagemDeRetorno(response, msg) {
      if (response && response.data.mensagem) {
        this.notifyWarning(response.data.mensagem);
      } else {
        this.notifyWarning(msg);
      }
    },
    associarUsuarioGre() {
      this.showPageLoading();
      let objetoRequisicao = {
        nomeDeUsuario: this.user.username,
        greId: this.usuarioAssociado.codigo,
        usuarioId: this.defaultUser.id,
        gestorId: this.defaultUser.gestorId,
      };

      this.$api()
        .post(
          configurations.api.admin.vinculacao.getAssociarUserGre,
          objetoRequisicao
        )
        .then(({ data }) => {
          if (data) {
            this.notifySuccess("Usuário vínculado com sucesso!");
            this.fecharDialogoGerente();
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos víncular o usuário.");
          }
        })
        .then(() => {
          this.getListGerente();
        });
    },
    desvincularUsuarioGre() {
      this.showPageLoading();
      let objetoRequisicao = {
        nomeDeUsuario: this.user.username,
        usuarioId: this.defaultUser.id,
      };

      this.$api()
        .put(
          configurations.api.admin.vinculacao.getUpdateUserGre,
          objetoRequisicao
        )
        .then(({ data }) => {
          if (data) {
            this.notifySuccess("Usuário desvinculado com sucesso!");
            this.fecharDialogoGerente();
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos víncular o usuário.");
          }
        })
        .then(() => {
          this.getListGerente();
        });
    },
    associarUsuarioDiretor() {
      this.showPageLoading();
      let objetoRequisicao = {
        nomeDeUsuario: this.user.username,
        greId: this.greSelecionada.codigo,
        usuarioId: this.defaultUser.id,
        gestorId: this.defaultUser.gestorId,
        codigoMec: this.escolaSelecionada.codigoMec,
      };

      this.$api()
        .post(
          configurations.api.admin.vinculacao.getAssociarUserEscola,
          objetoRequisicao
        )
        .then(({ data }) => {
          if (data) {
            this.notifySuccess("Usuário vínculado com sucesso!");
            this.fecharDialogoDiretor();
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos víncular o usuário.");
          }
        })
        .then(() => {
          this.getListDiretor();
        });

    },
    desvincularUsuarioDiretor() {
      this.showPageLoading();
      let objetoRequisicao = {
        nomeDeUsuario: this.user.username,
        usuarioId: this.defaultUser.id,
      };

      this.$api()
        .put(
          configurations.api.admin.vinculacao.getDesvincularUserEscola,
          objetoRequisicao
        )
        .then(({ data }) => {
          if (data) {
            this.notifySuccess("Usuário desvinculado com sucesso!");
            this.fecharDialogoDiretor();
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos víncular o usuário.");
          }
        })
        .then(() => {
          this.getListDiretor();
        });

    },
    editItemGerente(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexUser = this.editedIndex;
      this.defaultUser = Object.assign({}, item);
      if (item.gre === "") this.showDialog();
      else this.dialogPerguntaGerente = true;
    },
    editItemDiretor(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexUser = this.editedIndex;
      this.defaultUser = Object.assign({}, item);
      if (item.codigoMec == null) this.showDialogDiretor();
      else this.dialogPerguntaDiretor = true;
    },
    showDialog() {
      this.dialogGerente = true;
    },
    showDialogDiretor() {
      this.dialogDiretor = true;
    },
    fecharDialogoGerente() {
      this.defaultUser = Object.assign({}, this.cleanUser);
      this.editedIndex = -1;
      this.usuarioAssociado = undefined;
      this.dialogGerente = false;
      this.dialogPerguntaGerente = false;
    },
    fecharDialogoDiretor() {
      this.defaultUser = Object.assign({}, this.cleanUser);
      this.editedIndex = -1;
      this.greSelecionada = undefined;
      this.escolaSelecionada = undefined;
      this.escolas = [];
      this.usuarioAssociado = undefined;
      this.dialogDiretor = false;
      this.dialogPerguntaDiretor = false;
    },
    mostrarDataGridGerente(value) {
      this.inAtivaVinculacao = !value;
      this.inAtivaDataGridGerente = value;
    },
    mostrarDataGridDiretor(value) {
      this.inAtivaVinculacao = !value;
      this.inAtivaDataGridDiretor = value;
    },
    filter() {
      if (
        this.grid.filters.nome ||
        this.grid.filters.cpf ||
        this.grid.filters.gre
      ) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarMenuDoUsuario() {
      if (
        this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(
          (m) => m.url === "/admin/vinculacao"
        ).length === 0
      ) {
        this.$router.push({ path: "/login" });
      }
    },
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return (
          i.nome.toLowerCase().includes(this.grid.filters.nome.toLowerCase()) &&
          i.cpf.includes(this.grid.filters.cpf.toLowerCase()) &&
          i.gre.toLowerCase().includes(this.grid.filters.gre.toLowerCase())
        );
      });
    },
    filteredItemsDiretor() {
      return this.gridDiretor.data.filter((i) => {
        return (
          i.nomeDiretor.toLowerCase().includes(this.gridDiretor.filters.nomeDiretor.toLowerCase()) &&
          i.cpfDiretor.includes(this.gridDiretor.filters.cpfDiretor) && 
          i.nomeDaEscola.toLowerCase().includes(this.gridDiretor.filters.nomeDaEscola.toLowerCase())
        );
      });
    },
    rules() {
      return {
        // gre: [(value) => Validations.required(value)],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}
// .bt {
//   border: 0;
//   padding: 10px;
//   width: 300px;
//   height: 0;
//   display: inline-block;
//   margin: 30px;
//   cursor: pointer;
//   border-radius: 4px;
// }

// .bt2 {
//     text-align: center;
// }

.shorten {
  max-width: calc(100% - 260px);
}
.text1-h5{
  text-align: right;
  width: 340px;
  font-size: 120%;
   
}
</style>
